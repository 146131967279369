import { Link } from 'react-router-dom';
import { getFullPrice } from '../../join-commute/helpers/vanHelpers';
import { numberWithCommas } from '../../join-commute/helpers/commonHelper';
import { useEffect, useState } from 'react';
import dateUtility from '../../shared/dateUtility';
import analyticsService from '../../shared/analytics/analytics-service';

export default function SubscriptionPlan(props) {
  const { subscription, vanpoolInfo } = props;
  const [startDate, updateStartDate] = useState(null);
  const [endDate, updateEndDate] = useState(null);
  const [plan, updatePlan] = useState('');
  const [priceObj, updatePriceObj] = useState({});

  useEffect(() => {
    if(props.startDate){
      const startDay = dateUtility.parseDateAsMonthDayYearFromGMT(props.startDate);
      updateStartDate(startDay);
    }
    if(props.endDate){
      const endDay = dateUtility.parseDateAsMonthDayYearFromGMT(props.endDate);
      updateEndDate(endDay);
    }
    let planName = '';
    if(subscription){
      if(!subscription.package){
        if(props.subscriptionAPIInfo && props.subscriptionAPIInfo.package){
          planName = props.subscriptionAPIInfo.package.replace('X', '');
        } else {
          if(props.subscriptionAPIInfo && props.subscriptionAPIInfo.pendingChange){
            planName = props.subscriptionAPIInfo.pendingChange.package.replace('X', '');
          }
        }
      } else {
         planName = subscription.package.replace('X', '');
        }
        updatePlan(planName);
      }
    let price = {};
    if(props.profile && props.subscriptionAPIInfo && props.subscriptionAPIInfo.totalAmount){
      price = getFullPrice(props.profile.subscriptionAPIInfo.totalAmount);
    } else if(props.subscriptionAPIInfo && props.subscriptionAPIInfo.pendingChange && props.subscriptionAPIInfo.pendingChange.totalAmount){
      price = getFullPrice(props.subscriptionAPIInfo.pendingChange.totalAmount);
    }
    updatePriceObj(price);
  }, [props, props.subscriptionAPIInfo]);

  function clickManage(){
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "manage"
      }
    });
  }

  return (
    <div className='subscriptionPlan' role='group'>
      <div className='planDetails'>
        <div className='heading' role='group'>
          <div>
            <div className='sub-title'>Subscription Plan</div>
            {subscription && (subscription.package === 'Unlimited' ?
              <div className='plan'>Unlimited Plan</div> :
              <div className='plan'>{plan}-Day Plan</div>)
            }
          </div>
          {props.isVanStartingInFuture === true ?
            <div className='starting-date'>Starting {startDate}</div>
            :
            props.isVanCancelled === true ?
            <div className='ending-date'>Ending {endDate}</div>
            :
            (props.viewLink ? <Link className="view-link green-link-underline" to="/payment/summary/subscription">
              view plan
            </Link> :
              <Link className='manage-button' to="/myprofile" onClick={clickManage}>Manage</Link>)}
        </div>
        <div className='rate' role='group'>
          <div className='price-group' aria-label={`${priceObj.price} dollars and ${priceObj.decimal} cents`}>
            <div className='doller'>{'$'}</div>
            <div className='price'>{numberWithCommas(priceObj.price)}</div>
            <div className="decimal">.{priceObj.decimal}</div>
          </div>
          <div className='perMonth'>total per month</div>
        </div>
      </div>
      <div className='vl' aria-hidden='true'></div>
      <div className='vanpoolDetails' role='group'>
        <div className='vanpoolName'>
          <div className='name'>
            {vanpoolInfo.vanpoolName}
          </div>
          <div className='type'>
            {vanpoolInfo.vehicleType}
          </div>
        </div>
        {<div className='vanpoolId'>
          <div className='key'>
            Vanpool ID
          </div>
          <div className='id'>
            {vanpoolInfo.vanpoolId}
          </div>
        </div>}
      </div>
    </div>
  )
}