import { isEmpty } from 'lodash';
import GreenSeat from '../../../../_styles/images/GreenSeat.png';
import Steering from '../../../../_styles/images/Steering.png';
import GrayInfo from '../../../../_styles/images/new-icons/gray_info.svg';
import DownloadIcon from '../../../../_styles/images/downloadBlack.svg';
import CommuteDaysSection from './CommuteDaysSection';
import PreferredDaysSection from './PreferredDaysSection';
import { numberWithCommas } from '../../../../join-commute/helpers/commonHelper';
import { getDriverStatus } from '../../../helper';


function SubscriptionSection(props) {
  const {
    priceObj,
    badgeStatus,
    badgeText,
    background,
    showHeader,
    isDriver,
    showAgreement,
    downloadAgreement,
    driverPreferences,
    status,
    openEditSchedulePrefSection,
    getDriverPreferences,
    driverPreferencesError,
    driverInfo
  } = props;

  const statusInfo = getDriverStatus(status, driverInfo);

  return (
    <>
    <div className={`subscription-section ${background}`}>
      {showHeader && <div className="header">Manage Subscription</div>}
      <div className='sections'>
        {/* Left Section */}
        <div className="left-section">
          <div className="plan-details" role='group'>
            <div className={`plan ${badgeStatus}`}>
              {badgeText}
            </div>
            <div className="package">
              {props.plan === 'Unlimited' ? 'Unlimited Plan' : `${props.plan}-Day Plan`}
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="right-section" role='group'>
          <div className={'rate-details' + (props.showDriverAppPendingLabel === true ? ' grayed' : '')}>
            <img src={props.showDriverAppPendingLabel ? GrayInfo : isDriver ? Steering : GreenSeat} alt="seat image" aria-hidden='true' />
            <span>{isDriver ? 'Driver' : 'Rider'} rate{props.showDriverAppPendingLabel&&"*"}</span>
          </div>
          <div className="rate">
            <div className="price-group" role='text'>
              <span aria-hidden="true" className="price-group">
                <span className="doller">$</span>
                <span className="price">{numberWithCommas(priceObj.price)}</span>
                <span className="point">.</span>
                <span className="decimal">{priceObj.decimal}</span>
              </span>
              <span>
                <span className="screenreader-only">{priceObj.price} dollars and {priceObj.decimal} cents total per month</span>
              </span>
            </div>
            <div className="perMonth">total per month</div>
            {
              props.showDriverAppPendingLabel && <span className='pending-text'>*Rider rate displayed until<br/>driver application is approved</span>
            }
          </div>
        </div>
      </div>

      {/* Commute Days Section */}
      <CommuteDaysSection {...props} />
      {/* Preferences section */}
      {(background === 'active') && statusInfo && statusInfo.status === 'approved' &&
        <PreferredDaysSection
          driverPreferences={driverPreferences}
          openSection={openEditSchedulePrefSection}
          vanDays={props.vanpoolInfo.commuteDays}
          saveSchedulePreferences={props.saveSchedulePreferences}
          getDriverPreferences={getDriverPreferences}
          driverPreferencesError={driverPreferencesError}
          passAnalyticsClickEvent={props.passAnalyticsClickEvent} />
      }
    </div>
    {/* Agreement Section */}
    {showAgreement && (
      <div className='viewAgreement' onClick={() => downloadAgreement(isDriver ? "Download Driver Agreement" : "Download Rider Agreement")}>
        <img src={DownloadIcon} alt='' />
        {/* <span>{isDriver ? "Download Driver Agreement" : "Download Rider Agreement"}</span> */}
        <span>Download vanpool Agreement</span>
      </div>
    )}
    </>
  );
}

export default SubscriptionSection;
