import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import FuelCardIcon from '../../_styles/images/new-icons/Vector.png';
import dateUtility from '../../shared/dateUtility';
import Active from '../../_styles/images/new-icons/active.png';
import Inactive from '../../_styles/images/new-icons/inactive.png';
import * as actionTypes from '../../redux/actionTypes';
import WexCard from '../../_styles/images/new-icons/wex-card.png';
import CommonToast from '../../shared/components/common-toast-message/CommonToast';
import analyticsService from '../../shared/analytics/analytics-service';

function ActivateCard(props) {
    const [cardNumber, setCardNumber] = useState('');
    const [pin, setPin] = useState('');
    const [isActivated, setIsActivated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [toastData, updateToastData] = useState({
        message: '',
        type: '',
        timeOut: 0
    });

    useEffect(() =>{
        analyticsService.getPageName({
            'pagename': 'payment/fuel card',
            'process': 'add fuel card'});
    },[]);

    const handlePin1Change = (e) => {
        const inputValue = e.target.value;
        if (/^\d{0,18}$/.test(inputValue)) {
            setCardNumber(inputValue);
        }
      };
    
    const handlePin2Change = (e) => {
      const inputValue = e.target.value;
      if (/^\d{0,4}$/.test(inputValue)) {
        setPin(inputValue);
      }
    };

    useEffect(() => {
        setIsActivated(cardNumber.length === 18 && pin.length === 4);
    },[cardNumber, pin]);

    const activateCard = () => {
        const data = {
          cardNumber: cardNumber,
          pinNumber: pin
        };
        setIsLoading(true);
        props.activateCard(data);
        setCardNumber('');
        setPin('');
        setIsActivated(false);
        analyticsService.analyticsProcessEvent({
            "event": "activate_fuel_card_continue",
            "context": {
                "event_action": "activate"
                }
        });
    };

    useEffect(() => {
        if (props.activateCardRes && props.activateCardRes.success === false) {
            updateToastData({
                message: 'There was an error submitting your request. Please verify the digits and try again or call 800 VAN 4 WORK',
                type: 'error',
                timeOut: 0
            });
            setIsErrorModalOpen(true);
            props.updateActiveStatus()
        } else if (props.activateCardRes && props.activateCardRes.success === true) {
            updateToastData({
                message: 'Your fuel card is now active',
                type: 'success',
                timeOut: 0
            });
            setIsErrorModalOpen(true);
            props.updateActiveStatus()
        }
        setIsLoading(false)
    },[props.activateCardRes]);

    return (
    <>
        <div className='van-details'>
            <div className='fuel-card mobile-only'>
                <div className='text'>Fuel Card</div>
                <div className='active'>{props.fuelCardPayment.activeFuelCardDetails.cardStatus === 'Active' ? 
                        null : 
                        <div className='inactive-card'>
                            Not Active
                            <img src={Inactive} alt='not active card' aria-label='card status inactive'></img>
                        </div>}
                    </div>
            </div>
            <div className='top-section'>
                <div className='van-details-container'>
                    {props.fuelCardPayment.activeFuelCardDetails.cardStatus === 'Active' ? <><img src={FuelCardIcon} alt='fuel card icon' aria-label="wex card image"></img>
                    <div className='vanpool'>
                        <div className='vanpool-name'>{props.vanpoolInfo.vanpoolName}</div>
                        <div className='vanpool-id'>Vanpool ID: {props.vanpoolInfo.vanpoolId}</div>
                        {props.profile.isCoordinator && <div className='account'>Account number ending with {props.fuelCardPayment.activeFuelCardDetails.partialCardNumber}</div>}
                        </div></> :
                        <>
                        <img src={FuelCardIcon} alt='fuel card icon' aria-label="wex card image"></img>
                        <div className='commute-card'>Commute Fuel Card</div>
                    </> }
                </div>
                <div className='van-details-mobile mobile-only'>
                    {props.fuelCardPayment.activeFuelCardDetails.cardStatus === 'Active' ? <><img src={FuelCardIcon} alt='fuel card icon' aria-label="wex card image"></img>
                    <div className='vanpool'>
                        <div className='vanpool-name'>{props.vanpoolInfo.vanpoolName}</div>
                        <div className='vanpool-id'>Vanpool ID: {props.vanpoolInfo.vanpoolId}</div>
                        {props.profile.isCoordinator && <div className='account'>Account number ending with {props.fuelCardPayment.activeFuelCardDetails.partialCardNumber}</div>}
                        </div></> :
                        null}
                </div>
                <div className='right-section'>
                    <div className='active'>{props.fuelCardPayment.activeFuelCardDetails.cardStatus === 'Active' ? 
                        null : 
                        <div className='inactive-card'>
                            Not Active
                            <img src={Inactive} alt='not active card' aria-label='card status inactive'></img>
                        </div>}
                    </div>
                        {props.fuelCardPayment.activeFuelCardDetails.cardStatus === 'Active' ? 
                        <div className='last-updated'>Last updated {dateUtility.parseDateAsMonthDayYearFromDate(props.fuelCardPayment.summary.wexFilelastImportedDate)}</div> 
                        : null
                        }
                </div>
            </div>
            { props.fuelCardPayment.activeFuelCardDetails.cardStatus !== 'Active' && props.coordinatorStatus === 'Approved' ?
                <>
                    <div className='mid-section'>
                        <div className='card-details'>
                            <div className='info-text'>Enter your card number as displayed in the combination of<br/>13 digits + 5 digits, for a total of 18 digits</div>
                            <div className='info mobile-only'>Enter your card number as displayed below in the combination of 13 digits + 5 digits, for a total of 18 digits</div>
                            <div className='wex-card mobile-only'>
                                <img src={WexCard} alt='wex card' aria-label='wex card'></img>
                            </div>
                            <div className='add-card'>
                                <div className='text'>Enter Your 18 Fuel Card Digits</div>
                                <input
                                    className='number-input'
                                    type="text"
                                    value={cardNumber}
                                    onChange={(e) => handlePin1Change(e)}
                                />
                            </div>
                                <div className='bottom-text'>Digits only, no spaces or a dash</div>
                        </div>
                        <div className='wex-web'>
                            <img src={WexCard} alt='wex card' aria-label='wex card'></img>
                        </div>
                    </div>
                    <div className='bottom-section'>
                        <span className='text-pin'>Create a 4 digit PIN for your fuel card</span>
                        <div className='text'>Enter Your 4 Digit PIN</div>
                        <input
                            className='number-input'
                            type="text"
                            value={pin}
                            onChange={(e) => handlePin2Change(e)}
                        />
                        {isLoading && <div className="loader-overlay"><div className="loader"></div></div>}
                    </div>
                    <button
                        className={`primary button ${isActivated ? 'active' : 'disabled'}`}
                        onClick={activateCard}
                    >
                        ACTIVATE
                    </button>
                </> : <></>

            }
            <CommonToast message={toastData.message} type={toastData.type} isOpen={isErrorModalOpen} closeToast={() => setIsErrorModalOpen(!isErrorModalOpen)} timeOut={toastData.timeOut} />
        </div>
    </>
    );
}

function mapStateToProps(state) {
    return {
        fuelCardPayment: state.fuelCardPayment,
        vanpoolInfo: state.vanpoolInfo,
        profile: state.profile,
        details: state.details,
        activateCardRes: state.fuelCardPayment.activateCardRes
    }
}

function mapDispatchToProps(dispatch) {
    return {
        activateCard(data) {
            dispatch({ type: actionTypes.ACTIVATE_WEX_FUEL_CARD, payload: data });
        },
        updateActiveStatus() {
            dispatch({ type: actionTypes.ACTIVATE_WEX_FUEL_CARD_RES, data: null });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateCard);